<template>
    <div
        class="fixed mt-8 lg:mt-0 inset-0 z-20"
        v-if="isEditPlan || isCreatePlan"
    >
        <div
            class="absolute flex right-0 bg-white h-full shadow-lg transition-all w-full lg:w-1/3 desktop:w-1/4"
        >
            <div class="relative m-8 w-full justify-between ">
                <div class="desktop:pb-4">
                    <h2
                        v-if="isEditPlan"
                        class="max-w-6xl desktop:mt-16 text-lg desktop:text-2xl leading-6 text-left font-bold text-gray-900"
                    >
                        {{ $t("users.edit_spending") }}
                    </h2>
                    <h2
                        v-if="isCreatePlan"
                        class="max-w-6xl desktop:mt-16 text-lg desktop:text-2xl leading-6 text-left font-bold text-gray-900"
                    >
                        {{ $t("users.create_spending") }}
                    </h2>
                    <div class="mt-4 desktop:mt-10 px-2 desktop:px-4">
                        <label
                            for="plan_name"
                            class="block ml-1 text-sm desktop:text-lg font-medium text-gray-500 text-left"
                            >{{ $t("users.plan_name") }}</label
                        >
                        <input
                            name="plan_name"
                            id="plan_name"
                            ref="plan_name"
                            class="mt-1 mb-2 desktop:mb-8 border w-full border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 text-sm desktop:text-base"
                        />
                        <label
                            for="plan_desc"
                            class="block ml-1 text-sm desktop:text-lg font-medium text-gray-500 text-left"
                            >{{ $t("users.description") }}</label
                        >
                        <input
                            name="plan_desc"
                            id="plan_desc"
                            ref="plan_desc"
                            class="mt-1 mb-4 desktop:mb-10 border w-full border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 text-sm desktop:text-base"
                        />
                        <div
                            class="block text-sm desktop:text-lg font-medium text-gray-500 text-left"
                        >
                            {{ $t("users.bookable_type") }}
                        </div>
                        <div class="flex items-center mt-2 desktop:mt-6">
                            <span
                                class="pr-3 text-sm text-left desktop:text-lg w-1/2 desktop:w-1/4"
                                >{{ $t("users.hot_desk") }}</span
                            >
                            <Switch :toggle="work" @onPress="work = !work" />
                        </div>
                        <div class="flex items-center mt-3 desktop:mt-8">
                            <span
                                class="pr-3 text-sm text-left desktop:text-lg w-1/2 desktop:w-1/4"
                            >
                                {{ $t("users.meeting_room") }}
                            </span>
                            <Switch :toggle="meet" @onPress="meet = !meet" />
                        </div>
                        <div
                            class="block mt-4 desktop:mt-10 text-sm desktop:text-lg font-medium text-gray-500 text-left"
                        >
                            {{ $t("users.total_allowance") }}
                        </div>
                        <div
                            class="text-left mt-2 mb-6 desktop:mt-4 desktop:mb-12 text-sm desktop:text-base"
                        >
                            HK$
                            <input
                                ref="allowance_amt"
                                type="number"
                                min="1"
                                class="border border-gray-300 rounded-md shadow-sm mx-1 pl-2 py-1 focus:outline-none focus:ring-gray-900 focus:border-gray-900"
                            />
                            {{ $t("users.per") }}
                            <span class=" pl-1 mx-1"
                                ><select ref="allowance_type" class="border"
                                    ><option
                                        :selected="allowance_type === 'weekly'"
                                        value="weekly"
                                        >{{ $t("users.week") }}</option
                                    >
                                    <option
                                        :selected="allowance_type === 'monthly'"
                                        value="monthly"
                                        >{{ $t("users.month") }}</option
                                    >
                                </select></span
                            >
                        </div>
                        <div
                            class="block text-sm desktop:text-lg font-medium text-gray-500 text-left"
                        >
                            {{ $t("users.allowance_per_book") }}
                        </div>
                        <div
                            class="text-left mt-2 mb-6 desktop:mt-4 desktop:mb-12 text-sm desktop:text-base"
                        >
                            HK$
                            <input
                                ref="reservation_max_amt"
                                type="number"
                                min="1"
                                class=" border border-gray-300 rounded-md shadow-sm mx-1 py-1 pl-2 focus:outline-none focus:ring-gray-900 focus:border-gray-900"
                            />
                            {{ $t("users.per") }}{{ $t("users.booking") }}
                        </div>
                        <div
                            class="block text-sm desktop:text-lg font-medium text-gray-500 text-left"
                        >
                            {{ $t("users.booking_num") }}
                        </div>
                        <div
                            class="text-left mt-2 mb-6 desktop:mt-4 desktop:mb-12  text-sm desktop:text-base"
                        >
                            <input
                                ref="frequency"
                                type="number"
                                min="1"
                                class=" border border-gray-300 rounded-md shadow-sm py-1 pl-2 focus:outline-none focus:ring-gray-900 focus:border-gray-900"
                            />
                            {{ $t("users.per") }}
                            <span class=" pl-1 mx-1"
                                ><select ref="frequency_type" class="border"
                                    ><option
                                        :selected="frequency_type === 'weekly'"
                                        value="weekly"
                                        >{{ $t("users.week") }}</option
                                    >
                                    <option
                                        :selected="frequency_type === 'monthly'"
                                        value="monthly"
                                        >{{ $t("users.month") }}</option
                                    >
                                </select></span
                            >
                        </div>
                    </div>
                </div>
                <div class="absolute bottom-0 right-0">
                    <div class="flex justify-between">
                        <div></div>
                        <div>
                            <button
                                @click="onDiscard"
                                class="bg-white border border-gray-400 rounded-md text-black py-1 px-4 desktop:py-2 desktop:px-8 mr-3"
                            >
                                {{ $t("button.discard") }}
                            </button>
                            <button
                                v-if="isEditPlan"
                                @click="onSave"
                                :disabled="isSubmitted"
                                class="bg-black rounded-md text-white py-1 px-4 ml-2 desktop:py-2 desktop:px-8 transition duration-500 ease-in-out"
                                :class="{ 'opacity-30': isSubmitted }"
                            >
                                {{ $t("button.save") }}
                            </button>
                            <button
                                @click="addPlan"
                                v-if="isCreatePlan"
                                :disabled="isSubmitted"
                                class="bg-black rounded-md text-white py-1 px-4 ml-2 desktop:py-2 desktop:px-8 transition duration-500 ease-in-out"
                                :class="{ 'opacity-30': isSubmitted }"
                            >
                                {{ $t("button.create") }}
                            </button>
                        </div>
                    </div>
                </div>
                <!--                                <div class="mt-36 desktop:mt-80">-->
                <!--                                    <div class="flex justify-between">-->
                <!--                                        <div></div>-->
                <!--                                        <div>-->
                <!--                                            <button-->
                <!--                                                @click="onDiscard"-->
                <!--                                                class="bg-white border border-gray-400 rounded-md text-black py-1 px-4 desktop:py-2 desktop:px-8 mr-3"-->
                <!--                                            >-->
                <!--                                                {{ $t("button.discard") }}-->
                <!--                                            </button>-->
                <!--                                            <button-->
                <!--                                                v-if="isEditPlan"-->
                <!--                                                @click="updatePlan"-->
                <!--                                                class="bg-black rounded-md text-white py-1 px-4 ml-2 desktop:py-2 desktop:px-8"-->
                <!--                                            >-->
                <!--                                                {{ $t("button.save") }}-->
                <!--                                            </button>-->
                <!--                                            <button-->
                <!--                                                @click="addPlan"-->
                <!--                                                v-if="isCreatePlan"-->
                <!--                                                class="bg-black rounded-md text-white py-1 px-4 ml-2 desktop:py-2 desktop:px-8"-->
                <!--                                            >-->
                <!--                                                {{ $t("button.create") }}-->
                <!--                                            </button>-->
                <!--                                        </div>-->
                <!--                                    </div>-->
                <!--                                </div>-->
            </div>
        </div>
    </div>
</template>

<script>
import Switch from "@/components/Switch"
export default {
    name: "PlanSidebar",
    data() {
        return {
            planID: -1,
            isInitial: true,
            isSubmitted: false,
            work: false,
            meet: false
        }
    },
    components: { Switch },
    props: {
        planName: String,
        planCount: Number,
        planDesc: String,
        isEditPlan: Boolean,
        isCreatePlan: Boolean,
        allowance_type: String,
        allowance: Number,
        reservation_max_amt: Number,
        frequency_type: String,
        frequency: Number
    },
    emits: ["onDiscard", "onSave"],
    updated() {
        if (this.isEditPlan && this.isInitial) {
            this.$refs.plan_name.value = this.planName
            if (this.planDesc) {
                this.$refs.plan_desc.value = this.planDesc
            }
            this.$refs.allowance_amt.value = this.allowance
            this.$refs.reservation_max_amt.value = this.reservation_max_amt
            this.$refs.frequency.value = this.frequency
            this.isInitial = false
        }
    },
    // beforeRouteLeave(to, from, next) {
    //     const answer = window.confirm(
    //         this.$i18n.locale === "en"
    //             ? `There are ${this.planUsers} users currently associated with this Spending Plan.`
    //             : "變更尚未儲存，你要離開此頁嗎？"
    //     )
    //     if (answer) {
    //         next()
    //     } else {
    //         next(false)
    //     }
    // },
    methods: {
        onInitial() {
            this.isInitial = true
        },
        updateUsage(id, work, meet) {
            this.planID = id
            this.work = work
            this.meet = meet
        },
        onDiscard() {
            this.$emit("onDiscard")
        },
        onSave() {
            const answer = window.confirm(
                this.$i18n.locale === "en"
                    ? `There are ${this.planCount} users currently associated with this Spending Plan.\nChanges will apply to the users immediately.`
                    : `當前有${this.planCount}個用戶正在參與此支出計劃。\n更改將會立即套用至用戶。`
            )
            if (answer) {
                this.updatePlan()
            }
        },
        async addPlan() {
            this.isSubmitted = true
            const token = await this.$auth.getTokenSilently()
            const data = {
                user_id: this.$auth.user.value.sub,
                plan_name: this.$refs.plan_name.value,
                plan_description_en: this.$refs.plan_desc.value,
                plan_description_tc: this.$refs.plan_desc.value,
                allowance_type: this.$refs.allowance_type.value,
                allowance_amt: Number(this.$refs.allowance_amt.value),
                reservation_max_amt: Number(
                    this.$refs.reservation_max_amt.value
                ),
                frequency_type: this.$refs.frequency_type.value,
                frequency: Number(this.$refs.frequency.value),
                work_usage: +this.work,
                meet_usage: +this.meet
            }
            const url =
                process.env.VUE_APP_API_BASE_URL +
                `/plan/add?lang=${this.$i18n.locale}`
            if (
                data.plan_name !== "" &&
                data.allowance_amt !== "" &&
                data.allowance_amt > 0 &&
                data.reservation_max_amt !== "" &&
                data.reservation_max_amt > 0 &&
                data.frequency !== "" &&
                data.frequency > 0
            ) {
                this.$axios
                    .post(url, data, {
                        headers: {
                            Authorization: `Bearer ${token}` // send the access token through the 'Authorization' header
                        }
                    })
                    .then(res => {
                        this.$emit("onSave")
                    })
            } else alert("Empty input.")
            setTimeout(() => {
                this.isSubmitted = false
            }, 1200)
        },
        async updatePlan() {
            this.isSubmitted = true
            const token = await this.$auth.getTokenSilently()
            const data = {
                user_id: this.$auth.user.value.sub,
                plan_id: this.planID,
                plan_name: this.$refs.plan_name.value,
                plan_description_en: this.$refs.plan_desc.value,
                plan_description_tc: this.$refs.plan_desc.value,
                allowance_type: this.$refs.allowance_type.value,
                allowance_amt: Number(this.$refs.allowance_amt.value),
                reservation_max_amt: Number(
                    this.$refs.reservation_max_amt.value
                ),
                frequency_type: this.$refs.frequency_type.value,
                frequency: Number(this.$refs.frequency.value),
                work_usage: +this.work,
                meet_usage: +this.meet
            }
            const url =
                process.env.VUE_APP_API_BASE_URL +
                `/plan/edit?lang=${this.$i18n.locale}`
            if (
                data.plan_name !== "" &&
                data.allowance_amt !== "" &&
                data.allowance_amt > 0 &&
                data.reservation_max_amt !== "" &&
                data.reservation_max_amt > 0 &&
                data.frequency !== "" &&
                data.frequency > 0
            ) {
                this.$axios
                    .put(url, data, {
                        headers: {
                            Authorization: `Bearer ${token}` // send the access token through the 'Authorization' header
                        }
                    })
                    .then(res => {
                        this.$emit("onSave")
                    })
            } else alert("Empty input.")
            setTimeout(() => {
                this.isSubmitted = false
            }, 1200)
        }
    }
}
</script>

<style scoped></style>
