<template>
    <div>
        <div
            v-if="!isLoading && !isLoading2"
            class="bg-gray-100 overflow-hidden h-full"
        >
            <div
                :class="{
                    'opacity-50':
                        isEditUser || isCreateUser || isEditPlan || isCreatePlan
                }"
            >
                <div class="m-8 pb-8 bg-white rounded-xl shadow-xl">
                    <div class="flex justify-between py-6">
                        <h2
                            class="max-w-6xl px-4 text-lg leading-6 text-left font-bold text-gray-900"
                        >
                            {{ $t("users.user_list") }}
                        </h2>
                        <div v-if="this.user.role !== 1">
                            <button
                                type="button"
                                class="mr-5 rounded-md text-cyan-700 hover:text-cyan-900"
                                @click="addUser"
                            >
                                {{ $t("users.add_user") }}
                            </button>
                            <button
                                type="button"
                                class="mr-5 rounded-md text-cyan-700 hover:text-cyan-900"
                                @click="downloadTemplate"
                            >
                                {{ $t("users.template") }}
                            </button>
                            <button
                                type="button"
                                class="mr-5 rounded-md text-cyan-700 hover:text-cyan-900"
                                @click="$refs.upload_csv.click()"
                            >
                                {{ $t("users.uploadCsv") }}
                            </button>
                        </div>
                    </div>
                    <div class="overflow-x-scroll border-b border-gray-200">
                        <table
                            class="table-auto min-w-full divide-y divide-gray-200"
                        >
                            <thead>
                                <tr>
                                    <th
                                        class="whitespace-nowrap pl-4 py-5 bg-gray-50 text-left text-sm border-r font-medium text-gray-500 tracking-wider"
                                    >
                                        {{ $t("users.username") }}
                                    </th>
                                    <th
                                        class="whitespace-nowrap pl-4 py-3 bg-gray-50 text-left text-sm border-r font-medium text-gray-500 tracking-wider"
                                    >
                                        {{ $t("users.spending_plan") }}
                                    </th>
                                    <th
                                        class="whitespace-nowrap pl-4 py-3 bg-gray-50 text-left text-sm border-r font-medium text-gray-500 tracking-wider"
                                    >
                                        {{ $t("users.added_date") }}
                                    </th>
                                    <th
                                        class="whitespace-nowrap pl-4 py-3 bg-gray-50 text-left text-sm border-r font-medium text-gray-500 tracking-wider"
                                    >
                                        {{ $t("users.last_booked") }}
                                    </th>
                                    <th
                                        class="whitespace-nowrap pl-4 py-3 bg-gray-50 text-left text-sm border-r font-medium text-gray-500 tracking-wider"
                                    >
                                        {{ $t("users.total_usage") }}
                                    </th>
                                    <th
                                        v-if="this.user.role !== 1"
                                        class="whitespace-nowrap pl-4 py-3 bg-gray-50 text-left text-sm border-r font-medium text-gray-500 tracking-wider"
                                    >
                                        {{ $t("users.actions") }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody
                                v-if="!isLoading && !isLoading2"
                                class="bg-white divide-y divide-gray-200"
                            >
                                <tr
                                    class="bg-white text-left font-medium text-tiny"
                                    v-for="(user, index) in userList.list"
                                    :key="index"
                                >
                                    <td class="border-r pl-4 pr-2 py-3">
                                        {{ user.user_name }}
                                        <div class="mt-2">
                                            {{ user.email }}
                                        </div>
                                    </td>
                                    <td
                                        class="border-r pl-4"
                                        v-if="user.plan_id"
                                    >
                                        <span
                                            v-for="(plan,
                                            index) in user.plan_id"
                                            :key="index"
                                        >
                                            <span
                                                v-if="
                                                    planList.list.filter(
                                                        i => i.plan_id === plan
                                                    )[0]
                                                "
                                            >
                                                {{
                                                    planList.list.filter(
                                                        i => i.plan_id === plan
                                                    )[0].plan_name
                                                }}{{
                                                    addComma(
                                                        user.plan_id,
                                                        index
                                                    )
                                                }}
                                            </span>
                                        </span>
                                    </td>
                                    <td
                                        class="border-r pl-4"
                                        v-if="user.plan_id === null"
                                    ></td>
                                    <td class="border-r pl-4">
                                        {{ user.invite_date }}
                                    </td>
                                    <td class="border-r pl-4">
                                        <div v-if="user.state === 1">
                                            {{ user.last_book }}
                                        </div>
                                    </td>
                                    <td class="border-r pl-4">
                                        <div v-if="user.state === 1">
                                            {{ user.usage }}
                                        </div>
                                    </td>
                                    <td
                                        v-if="this.user.role !== 1"
                                        class=" pl-4"
                                    >
                                        <div v-if="user.state === 1">
                                            <div class=" flex-shrink-0">
                                                <button
                                                    @click="
                                                        removeUser(
                                                            user.profile_id
                                                        )
                                                    "
                                                    class="pr-1 text-cyan-700 hover:text-cyan-900 text-sm font-medium"
                                                >
                                                    {{ $t("button.remove") }}
                                                </button>
                                                /
                                                <button
                                                    @click="editAccess(index)"
                                                    class="pl-1 text-cyan-700 hover:text-cyan-900 text-sm font-medium"
                                                >
                                                    {{
                                                        $t("users.edit_access")
                                                    }}
                                                </button>
                                            </div>
                                        </div>
                                        <div v-if="user.state === 0">
                                            <div class="flex-shrink-0">
                                                <button
                                                    @click="
                                                        removeInvitation(
                                                            user.pending_id
                                                        )
                                                    "
                                                    class=" text-cyan-700 hover:text-cyan-900 text-sm font-medium"
                                                >
                                                    {{
                                                        $t(
                                                            "users.remove_invite"
                                                        )
                                                    }}
                                                </button>
                                            </div>
                                        </div>
                                    </td>
                                </tr>

                                <!-- More items... -->
                            </tbody>
                        </table>
                    </div>
                    <div
                        v-if="userList.total_page >= 1"
                        class="mt-8 flex flex-wrap justify-center items-center"
                    >
                        <button
                            @click="currentPage > 1 ? pageAction(0) : ''"
                            type="button"
                            class="px-6 py-1 border rounded-md"
                        >
                            {{ $t("button.prev_pg") }}
                        </button>
                        <span class="mx-4">
                            {{ $t("page1") }} {{ currentPage }}/{{
                                userList.total_page
                            }}{{ $t("page2") }}</span
                        >
                        <button
                            @click="
                                currentPage < userList.total_page
                                    ? pageAction(1)
                                    : ''
                            "
                            type="button"
                            class="px-6 py-1 border rounded-md"
                        >
                            {{ $t("button.next_pg") }}
                        </button>
                    </div>
                </div>
                <div class="m-8 pb-8 bg-white rounded-xl shadow-xl">
                    <div class="flex justify-between">
                        <h2
                            class="max-w-6xl py-6 mt-4 px-4 text-lg leading-6 text-left font-bold text-gray-900"
                        >
                            {{ $t("users.plans") }}
                        </h2>
                        <button
                            v-if="this.user.role !== 1"
                            type="button"
                            class="mr-5 mt-4 rounded-md text-cyan-700 hover:text-cyan-900"
                            @click="onCreate"
                        >
                            {{ $t("users.create_plan") }}
                        </button>
                    </div>
                    <div class="overflow-x-scroll border-gray-200 pb-16">
                        <table
                            class="table-auto min-w-full divide-y divide-gray-200"
                        >
                            <thead>
                                <tr>
                                    <th
                                        class="whitespace-nowrap pl-4 py-5 bg-gray-50 text-left text-sm border-r font-medium text-gray-500 tracking-wider"
                                    >
                                        {{ $t("users.plan_name") }}
                                    </th>
                                    <th
                                        class="whitespace-nowrap pl-4 py-5 bg-gray-50 text-left text-sm border-r font-medium text-gray-500 tracking-wider"
                                    >
                                        {{ $t("users.bookable_type") }}
                                    </th>
                                    <th
                                        class="whitespace-nowrap pl-4 py-5 bg-gray-50 text-left text-sm border-r font-medium text-gray-500 tracking-wider"
                                    >
                                        {{ $t("users.total_allowance") }}
                                    </th>
                                    <th
                                        class="whitespace-nowrap pl-4 py-5 bg-gray-50 text-left text-sm border-r font-medium text-gray-500 tracking-wider"
                                    >
                                        {{ $t("users.allowance_per_book") }}
                                    </th>
                                    <th
                                        class="whitespace-nowrap pl-4 py-5 bg-gray-50 text-left text-sm border-r font-medium text-gray-500 tracking-wider"
                                    >
                                        {{ $t("users.booking_num") }}
                                    </th>
                                    <th
                                        v-if="this.user.role !== 1"
                                        class="whitespace-nowrap pl-4 py-5 bg-gray-50 text-left text-sm border-r font-medium text-gray-500 tracking-wider"
                                    >
                                        {{ $t("users.actions") }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody
                                v-if="!isLoading2"
                                class="bg-white divide-y divide-gray-200"
                            >
                                <tr
                                    class="bg-white text-left font-medium text-tiny"
                                    v-for="(plan, index) in planList.list"
                                    :key="index"
                                >
                                    <td
                                        class="border-r pl-4 whitespace-nowrap py-3 border-b"
                                    >
                                        {{ plan.plan_name }}
                                        <div
                                            v-if="plan.plan_description_en"
                                            class="text-gray-400 text-sm"
                                        >
                                            {{ plan.plan_description_en }}
                                        </div>
                                    </td>
                                    <td
                                        class="border-r whitespace-nowrap pl-4 py-3 border-b"
                                    >
                                        <span
                                            v-for="(space,
                                            index) in plan.bookable"
                                            :key="index"
                                        >
                                            {{ space
                                            }}{{
                                                addComma(plan.bookable, index)
                                            }}
                                        </span>
                                    </td>
                                    <td class="border-r pl-4 border-b">
                                        HK${{ plan.allowance_amt }} /
                                        {{ plan.allowance_type }}
                                    </td>
                                    <td class="border-r pl-4 border-b">
                                        MAX. HK${{ plan.reservation_max_amt }}
                                    </td>
                                    <td class="border-r pl-4 border-b">
                                        {{ plan.frequency }} /
                                        {{ plan.frequency_type }}
                                    </td>
                                    <td
                                        v-if="this.user.role !== 1"
                                        class="border-r border-b pl-4"
                                    >
                                        <button
                                            @click="editPlan(plan)"
                                            class="text-cyan-700 hover:text-cyan-900 text-sm font-medium"
                                        >
                                            {{ $t("button.edit") }}
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <input
                type="file"
                ref="upload_csv"
                style="display:none"
                accept="text/csv"
                @change="readCSV"
            />
            <UserSidebar
                ref="user"
                :isCreateUser="isCreateUser"
                :isEditUser="isEditUser"
                :planList="planList.list"
                :user="userEdit"
                @onSave="onSaveUser"
                @onDiscard="onDiscard"
            />
            <PlanSidebar
                ref="plan"
                :isEditPlan="isEditPlan"
                :isCreatePlan="isCreatePlan"
                :planCount="planUsers"
                :planName="spendingPlan.name"
                :planDesc="spendingPlan.description"
                :allowance_type="spendingPlan.allowance_type"
                :allowance="spendingPlan.allowance"
                :reservation_max_amt="spendingPlan.reservation_max_amt"
                :frequency_type="spendingPlan.frequency_type"
                :frequency="spendingPlan.frequency"
                @onDiscard="onDiscard"
                @onSave="onSave"
            />
        </div>
        <div
            v-if="isLoading || isLoading2"
            class="flex justify-center mt-20 items-center"
        >
            <div class="w-1/5">
                <Spinner />
            </div>
        </div>
    </div>
</template>

<script>
import PlanSidebar from "@/components/PlanSidebar"
import UserSidebar from "@/components/UserSidebar"
import Spinner from "@/components/Spinner"
export default {
    name: "Users",
    components: { UserSidebar, PlanSidebar, Spinner },
    emits: ["disableScroll", "enableScroll"],
    data() {
        return {
            currentPage: 1,
            csv: "",
            sample_dial_code: "+852",
            sample_phone: "12345678",
            isEdit: false,
            isEditUser: false,
            userEdit: {},
            isLoading: true,
            isLoading2: true,
            work_usage: false,
            meet_usage: false,
            planUsers: 0,
            isEditPlan: false,
            isCreateUser: false,
            isCreatePlan: false,
            spendingPlan: {
                id: "",
                name: "",
                description: "",
                work: false,
                meet: false,
                allowance_type: "",
                allowance: -1,
                reservation_max_amt: -1,
                frequency_type: "",
                frequency: -1
            },
            userList: [],
            planList: []
        }
    },
    props: {
        user: { required: true, type: Object },
        enterprise: { required: false }
    },
    watch: {
        enterprise(to, from) {
            if (this.user.role === 1) {
                this.getUserList()
                this.getPlanList()
                this.currentPage = 1
            }
        }
    },
    beforeRouteLeave(to, from, next) {
        if (this.isEditPlan) {
            const answer = window.confirm(
                this.$i18n.locale === "en"
                    ? "Changes are not saved yet. Do you want to discard the edit?"
                    : "變更尚未儲存，你確定要離開嗎？"
            )
            if (answer) {
                next()
            } else {
                next(false)
            }
        } else next()
    },
    mounted() {
        this.getUserList()
        this.getPlanList()
    },
    methods: {
        async getUserList() {
            const token = await this.$auth.getTokenSilently()
            let url = ""
            if (this.user.role === 1) {
                url =
                    process.env.VUE_APP_API_BASE_URL +
                    `/user/list?user_id=${this.$auth.user.value.sub}&lang=${this.$i18n.locale}&page=${this.currentPage}&enterprise_id=${this.enterprise}`
            } else
                url =
                    process.env.VUE_APP_API_BASE_URL +
                    `/user/list?user_id=${this.$auth.user.value.sub}&lang=${this.$i18n.locale}&page=${this.currentPage}`
            this.$axios
                .get(url, {
                    headers: {
                        Authorization: `Bearer ${token}` // send the access token through the 'Authorization' header
                    }
                })
                .then(response => {
                    this.userList = response.data.Data
                    this.isLoading = false
                })
        },
        async getPlanList() {
            const token = await this.$auth.getTokenSilently()
            let url = ""
            if (this.user.role === 1) {
                url =
                    process.env.VUE_APP_API_BASE_URL +
                    `/plan/list?user_id=${this.$auth.user.value.sub}&lang=${this.$i18n.locale}&page=${this.currentPage}&enterprise_id=${this.enterprise}`
            } else
                url =
                    process.env.VUE_APP_API_BASE_URL +
                    `/plan/list?user_id=${this.$auth.user.value.sub}&lang=${this.$i18n.locale}&page=${this.currentPage}`
            this.$axios
                .get(url, {
                    headers: {
                        Authorization: `Bearer ${token}` // send the access token through the 'Authorization' header
                    }
                })
                .then(response => {
                    this.planList = response.data.Data
                    this.isLoading2 = false
                })
        },
        async removeUser(profileID) {
            const token = await this.$auth.getTokenSilently()
            const data = {
                user_id: this.$auth.user.value.sub,
                profile_id: profileID
            }
            const url =
                process.env.VUE_APP_API_BASE_URL +
                `/user/account/remove?lang=${this.$i18n.locale}`
            const answer = window.confirm(
                "Are you sure you want to remove this user?"
            )
            if (answer) {
                this.$axios
                    .put(url, data, {
                        headers: {
                            Authorization: `Bearer ${token}` // send the access token through the 'Authorization' header
                        }
                    })
                    .then(() => {
                        this.getUserList()
                    })
            }
        },
        async removeInvitation(pendingID) {
            const token = await this.$auth.getTokenSilently()
            const data = {
                user_id: this.$auth.user.value.sub,
                pending_id: pendingID
            }
            const url =
                process.env.VUE_APP_API_BASE_URL +
                `/user/invitation/remove?lang=${this.$i18n.locale}`
            const answer = window.confirm(
                "Are you sure you want to remove this invitation?"
            )
            if (answer) {
                await this.$axios
                    .put(url, data, {
                        headers: {
                            Authorization: `Bearer ${token}` // send the access token through the 'Authorization' header
                        }
                    })
                    .then(res => {
                        if (res.data.Success === true) {
                            setTimeout(() => {
                                this.getUserList()
                            }, 1200)
                        }
                    })
            }
        },
        async addUser() {
            this.isCreateUser = true
            this.$emit("disableScroll")
        },
        addComma(arr, index) {
            if (index !== arr.length - 1) {
                return ", "
            }
        },
        planCount(planID) {
            for (let i = 0; i < this.userList.list.length; i++) {
                if (this.userList.list[i].plan_id.includes(planID)) {
                    this.planUsers += 1
                }
            }
        },
        editPlan(plan) {
            this.$refs.plan.onInitial()
            this.planCount(plan.plan_id)
            this.$emit("disableScroll")
            this.spendingPlan.name = plan.plan_name
            this.spendingPlan.description = plan.plan_description_en
            if (
                plan.bookable.includes("Hot Desk") ||
                plan.bookable.includes("流動辦公桌")
            ) {
                this.spendingPlan.work = true
            }
            if (
                plan.bookable.includes("Meeting Room") ||
                plan.bookable.includes("會議室")
            ) {
                this.spendingPlan.meet = true
            }
            this.$refs.plan.updateUsage(
                plan.plan_id,
                this.spendingPlan.work,
                this.spendingPlan.meet
            )
            this.spendingPlan.allowance_type = plan.allowance_type
            this.spendingPlan.allowance = plan.allowance_amt
            this.spendingPlan.reservation_max_amt = plan.reservation_max_amt
            this.spendingPlan.frequency_type = plan.frequency_type
            this.spendingPlan.frequency = plan.frequency
            this.isEditPlan = true
        },
        onCreate() {
            this.$emit("disableScroll")
            this.isCreatePlan = true
        },
        onDiscard() {
            this.$emit("enableScroll")
            this.isEditPlan = false
            this.isEditUser = false
            this.planUsers = 0
            this.isCreatePlan = false
            this.isCreateUser = false
            this.spendingPlan.id = ""
            this.spendingPlan.name = ""
            this.spendingPlan.description = ""
            this.spendingPlan.work = false
            this.spendingPlan.meet = false
            this.$refs.plan.updateUsage(
                this.spendingPlan.id,
                this.spendingPlan.work,
                this.spendingPlan.meet
            )
            this.spendingPlan.allowance_type = ""
            this.spendingPlan.allowance = -1
            this.spendingPlan.reservation_max_amt = -1
            this.spendingPlan.frequency_type = ""
            this.spendingPlan.frequency = -1
        },
        onSave() {
            this.getPlanList()
            this.$emit("enableScroll")
            this.planUsers = 0
            this.isEditPlan = false
            this.isCreatePlan = false
        },
        onSaveUser() {
            this.getUserList()
            this.$emit("enableScroll")
            this.isEditUser = false
            this.isCreateUser = false
        },
        editAccess(index) {
            this.$refs.user.onInitial()
            this.isEditUser = true
            this.userEdit = this.userList.list[index]
            this.$emit("disableScroll")
        },
        readCSV() {
            let file = this.$refs.upload_csv.files
                ? this.$refs.upload_csv.files[0]
                : null
            if (file) {
                const reader = new FileReader()
                reader.onload = e => {
                    this.parseCSV(e.target.result, "\n", ",")
                }
                reader.readAsText(file)
            }
        },
        async parseCSV(text, lineTerminator, cellTerminator) {
            await this.getPlanList()
            let lines = text.split(lineTerminator)
            let errorEntries = []
            if (lines.length < 52) {
                for (let j = 1; j < lines.length; j++) {
                    if (lines[j] !== "") {
                        let information = lines[j].split(cellTerminator)
                        let selectedPlans = []
                        for (let i = 7; i < 11; i++) {
                            if (
                                information[i] &&
                                this.planList.list.filter(
                                    k =>
                                        k.plan_name.toLowerCase() ===
                                        information[i].toLowerCase()
                                )[0]
                            ) {
                                let ans = this.planList.list.filter(
                                    k =>
                                        k.plan_name.toLowerCase() ===
                                        information[i].toLowerCase()
                                )[0].plan_id
                                selectedPlans.push(ans)
                            }
                        }
                        if (
                            selectedPlans.length < 1 ||
                            !information[0] ||
                            !information[1] ||
                            !information[6]
                        ) {
                            const data = {
                                index: j + 1,
                                first_name: information[1],
                                last_name: information[0],
                                email: information[6]
                            }
                            errorEntries.push(data)
                        }
                        await this.inviteUser(
                            information[0],
                            information[1],
                            information[2],
                            information[3],
                            information[4],
                            information[5],
                            information[6],
                            selectedPlans
                        )
                    }
                }
                await this.getUserList()
                if (errorEntries.length > 0) {
                    let errorMsg =
                        "Failed to invite the following users: \n\n\n"
                    for (let i = 0; i < errorEntries.length; i++) {
                        errorMsg +=
                            "Line: " +
                            errorEntries[i].index +
                            "   Given name: " +
                            errorEntries[i].first_name +
                            "   Surname: " +
                            errorEntries[i].last_name +
                            "   Email: " +
                            errorEntries[i].email +
                            "\n"
                    }
                    alert(errorMsg)
                }
            }
        },
        async inviteUser(
            lName,
            fName,
            position,
            unit,
            dialCode,
            phone,
            email,
            plans
        ) {
            const token = await this.$auth.getTokenSilently()
            const data = {
                user_id: this.$auth.user.value.sub,
                surname: lName,
                given_name: fName,
                position: position,
                unit: unit,
                email: email,
                dial_code: dialCode.toString(),
                phone: phone.toString(),
                plan_id: plans
            }
            if (!data.dial_code.includes("+")) {
                data.dial_code = "+" + data.dial_code
            }

            const url =
                process.env.VUE_APP_API_BASE_URL +
                `/user/invite?lang=${this.$i18n.locale}`
            if (
                data.surname !== "" &&
                data.given_name !== "" &&
                data.email !== "" &&
                data.plan_id.length > 0
            ) {
                await this.$axios
                    .post(url, data, {
                        headers: {
                            Authorization: `Bearer ${token}` // send the access token through the 'Authorization' header
                        }
                    })
                    .then(res => {})
            }
        },
        async downloadTemplate() {
            this.csv = `Last name,First name,Position,Department,Dial code,Phone number,Email,Spending plan 1,Spending plan 2,Spending plan 3,Spending plan 4,Spending plan 5\nTo,Johnny,Manager Sales,workspace_1,${this.sample_dial_code},${this.sample_phone},example@example.com,PLEASE DELETE THIS ROW BEFORE UPLOAD`
            const anchor = document.createElement("a")
            this.csv = "\uFEFF" + this.csv
            anchor.href =
                "data:text/csv;charset=utf-8," + encodeURIComponent(this.csv)
            anchor.target = "_blank"
            anchor.download = `Invitation_template.csv`
            anchor.click()
        },
        pageAction(type) {
            if (type === 0) {
                this.currentPage -= 1
                this.getUserList()
            } else {
                this.currentPage += 1
                this.getUserList()
            }
        }
    }
}
</script>

<style scoped></style>
