<template>
    <div
        class="fixed mt-8 lg:mt-0 inset-0 z-20"
        v-if="isEditUser || isCreateUser"
    >
        <div
            class="absolute flex right-0 bg-white h-full shadow-lg transition-all w-full lg:w-3/7 desktop:w-1/4"
        >
            <div
                class="relative mx-8 my-4 desktop:my-8 w-full justify-between "
            >
                <div class="desktop:pb-4">
                    <h2
                        v-if="isEditUser"
                        class="max-w-6xl desktop:mt-16 text-lg desktop:text-2xl leading-6 text-left font-bold text-gray-900"
                    >
                        {{ $t("users.edit_user") }}
                    </h2>
                    <h2
                        v-if="isCreateUser"
                        class=" max-w-6xl desktop:mt-16 text-lg desktop:text-2xl leading-6 text-left font-bold text-gray-900"
                    >
                        {{ $t("users.add_user") }}
                    </h2>
                    <div class="mt-4 desktop:mt-10 px-2 desktop:px-4">
                        <label
                            for="last_name"
                            class="block ml-1 text-sm desktop:text-lg font-medium text-gray-500 text-left"
                            >{{ $t("users.l_name") }}
                            <span class="text-red-500">*</span></label
                        >
                        <input
                            name="last_name"
                            id="last_name"
                            ref="last_name"
                            class="mt-1 mb-2 desktop:mb-8 border w-full border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 text-sm desktop:text-base"
                        />
                        <label
                            for="first_name"
                            class="block ml-1 text-sm desktop:text-lg font-medium text-gray-500 text-left"
                            >{{ $t("users.f_name") }}
                            <span class="text-red-500">*</span></label
                        >

                        <input
                            name="first_name"
                            id="first_name"
                            ref="first_name"
                            class="mt-1 mb-4 desktop:mb-10 border w-full border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 text-sm desktop:text-base"
                        />
                        <label
                            for="email"
                            class="block ml-1 text-sm desktop:text-lg font-medium text-gray-500 text-left"
                            >{{ $t("users.email")
                            }}<span class="text-red-500">*</span>
                        </label>
                        <input
                            name="email"
                            id="email"
                            ref="email"
                            type="email"
                            :readonly="isEditUser"
                            class="mt-1 mb-4 desktop:mb-10 border w-full border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 text-sm desktop:text-base"
                        />
                        <label
                            for="phone"
                            class="block ml-1 text-sm desktop:text-lg font-medium text-gray-500 text-left"
                            >{{ $t("users.phone") }}
                        </label>
                        <div class="flex items-baseline">
                            <select
                                @change="changeCountryCode"
                                @focus="isDropDown = true"
                                @blur="isDropDown = false"
                                ref="country_code"
                                id="country_code"
                                class="border rounded text-center py-1 desktop:py-2 mr-5 max-w-1/4"
                                ><option
                                    v-for="(country, index) in countryCode"
                                    :value="country.code"
                                    :selected="
                                        country.code === this.user.dial_code
                                    "
                                    :key="index"
                                    :id="country.code"
                                >
                                    <span
                                        v-if="
                                            isDropDown && $i18n.locale === 'en'
                                        "
                                        >{{ country.English }}</span
                                    >
                                    <span
                                        v-if="
                                            isDropDown && $i18n.locale === 'zh'
                                        "
                                        >{{ country.Hongkong }}</span
                                    >
                                    {{ country.code }}
                                </option>
                            </select>
                            <input
                                name="phone"
                                id="phone"
                                ref="phone"
                                @keypress="isNumber($event)"
                                type="tel-national"
                                class="w-full mt-1 mb-4 desktop:mb-10 border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 text-sm desktop:text-base"
                            />
                        </div>
                        <label
                            for="job_position"
                            class="block ml-1 text-sm desktop:text-lg font-medium text-gray-500 text-left"
                            >{{ $t("users.position") }}
                        </label>
                        <input
                            name="job_position"
                            id="job_position"
                            ref="job_position"
                            class="mt-1 mb-4 desktop:mb-10 border w-full border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 text-sm desktop:text-base"
                        />
                        <label
                            for="job_department"
                            class="block ml-1 text-sm desktop:text-lg font-medium text-gray-500 text-left"
                            >{{ $t("users.unit") }}
                        </label>

                        <input
                            name="job_department"
                            id="job_department"
                            ref="job_department"
                            class="mt-1 mb-4 desktop:mb-10 border w-full border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 text-sm desktop:text-base"
                        />
                        <label
                            for="plans"
                            class="block ml-1 text-sm desktop:text-lg font-medium text-gray-500 text-left"
                            >{{ $t("users.spending_plan")
                            }}<span class="text-red-500">*</span>
                        </label>
                        <select
                            id="plans"
                            class="w-full my-1 desktop:my-3 border rounded-md flex text-center max-h-20 desktop:max-h-full"
                            v-model="selected"
                            multiple
                            ><option
                                v-for="(plan, index) in planList"
                                :value="plan.plan_id"
                                :key="index"
                            >
                                {{ plan.plan_name }}
                            </option></select
                        >
                        <span
                            class="text-xs text-left flex py-1 desktop:py-2 text-gray-500"
                        >
                            {{ $t("users.invitation_msg") }}
                        </span>
                    </div>
                </div>
                <div class="absolute bottom-0 right-0">
                    <div class="flex justify-between">
                        <div></div>
                        <div>
                            <button
                                @click="onDiscard"
                                class="bg-white border border-gray-400 rounded-md text-black py-1 px-4 desktop:py-2 desktop:px-8 mr-3"
                            >
                                {{ $t("button.discard") }}
                            </button>
                            <button
                                v-if="isEditUser"
                                @click="submitEdit"
                                :disabled="isSubmitted"
                                class="bg-black rounded-md text-white py-1 px-4 ml-2 desktop:py-2 desktop:px-8 transition duration-500 ease-in-out"
                                :class="{ 'opacity-30': isSubmitted }"
                            >
                                {{ $t("button.save") }}
                            </button>
                            <button
                                @click="inviteUser"
                                v-if="isCreateUser"
                                :disabled="isSubmitted"
                                class="bg-black rounded-md text-white py-1 px-4 ml-2 desktop:py-2 desktop:px-8 transition duration-500 ease-in-out"
                                :class="{ 'opacity-30': isSubmitted }"
                            >
                                {{ $t("button.create") }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import countryCode from "@/assets/json/country-code"
export default {
    name: "UserSidebar",
    data() {
        return {
            userID: -1,
            isInitial: true,
            isSubmitted: false,
            selected: [],
            countryCode: countryCode,
            isDropDown: false
        }
    },
    props: {
        planList: Object,
        user: Object,
        isEditUser: Boolean,
        isCreateUser: Boolean
    },
    emits: ["onDiscard", "onSave"],
    updated() {
        if (this.isEditUser && this.isInitial) {
            this.$refs.last_name.value = this.user.user_name.split(" ")[1]
            this.$refs.first_name.value = this.user.user_name.split(" ")[0]
            this.$refs.email.value = this.user.email
            this.$refs.phone.value = this.user.phone
            this.$refs.job_position.value = this.user.position
            this.$refs.job_department.value = this.user.unit
            this.selected = this.user.plan_id
            this.isInitial = false
        }
    },
    // updated() {
    //     if (this.isEditUser) {
    //         this.$refs.last_name.value = this.user.user_name.split(" ")[1]
    //         this.$refs.first_name.value = this.user.user_name.split(" ")[0]
    //         this.$refs.job_position.value = this.user.position
    //         this.$refs.job_department.value = this.user.unit
    //         this.$refs.email.value = this.user.email
    //         this.selected = this.user.plan_id
    //         this.isEditing = true
    //     }
    // },
    // beforeRouteLeave(to, from, next) {
    //     const answer = window.confirm(
    //         this.$i18n.locale === "en"
    //             ? `There are ${this.planUsers} users currently associated with this Spending Plan.`
    //             : "變更尚未儲存，你要離開此頁嗎？"
    //     )
    //     if (answer) {
    //         next()
    //     } else {
    //         next(false)
    //     }
    // },
    methods: {
        onInitial() {
            this.isInitial = true
        },
        onDiscard() {
            this.selected = []
            this.$emit("onDiscard")
        },
        changeCountryCode() {
            this.isDropDown = false
            this.$refs.phone.focus()
        },
        isNumber(e) {
            let char = e.key
            if (/^[0-9]+$/.test(char)) return true
            else e.preventDefault()
        },
        onSave() {
            const answer = window.confirm(
                this.$i18n.locale === "en"
                    ? `There are ${this.planCount} users currently associated with this Spending Plan.\nChanges will apply to the users immediately.`
                    : `當前有${this.planCount}個用戶正在參與此支出計劃。\n更改將會立即套用至用戶。`
            )
            if (answer) {
                this.updatePlan()
            }
        },
        async submitEdit() {
            this.isSubmitted = true
            const token = await this.$auth.getTokenSilently()
            const data = {
                user_id: this.$auth.user.value.sub,
                profile_id: this.user.profile_id,
                surname: this.$refs.last_name.value,
                given_name: this.$refs.first_name.value,
                dial_code: this.$refs.country_code.value,
                phone: this.$refs.phone.value,
                position: this.$refs.job_position.value,
                unit: this.$refs.job_department.value
            }
            const url =
                process.env.VUE_APP_API_BASE_URL +
                `/user/profile/edit?lang=${this.$i18n.locale}`
            if (data.surname !== "" && data.given_name !== "") {
                this.$axios
                    .put(url, data, {
                        headers: {
                            Authorization: `Bearer ${token}` // send the access token through the 'Authorization' header
                        }
                    })
                    .then(res => {
                        this.submitEditPlans()
                    })
            } else alert("Empty input.")
            setTimeout(() => {
                this.isSubmitted = false
            }, 1200)
        },
        async submitEditPlans() {
            const token = await this.$auth.getTokenSilently()
            const data = {
                user_id: this.$auth.user.value.sub,
                profile_id: this.user.profile_id,
                plan_list: this.selected
            }
            const url =
                process.env.VUE_APP_API_BASE_URL +
                `/user/plan/edit?lang=${this.$i18n.locale}`
            if (data.surname !== "" && data.given_name !== "") {
                this.$axios
                    .put(url, data, {
                        headers: {
                            Authorization: `Bearer ${token}` // send the access token through the 'Authorization' header
                        }
                    })
                    .then(res => {
                        this.$emit("onSave")
                    })
            } else alert("Empty input.")
        },
        async inviteUser() {
            this.isSubmitted = true
            const token = await this.$auth.getTokenSilently()
            const data = {
                user_id: this.$auth.user.value.sub,
                surname: this.$refs.last_name.value,
                given_name: this.$refs.first_name.value,
                email: this.$refs.email.value,
                dial_code: this.$refs.country_code.value,
                phone: this.$refs.phone.value,
                position: this.$refs.job_position.value,
                unit: this.$refs.job_department.value,
                plan_id: this.selected
            }
            const url =
                process.env.VUE_APP_API_BASE_URL +
                `/user/invite?lang=${this.$i18n.locale}`
            if (
                data.surname !== "" &&
                data.given_name !== "" &&
                data.email !== "" &&
                data.plan_id.length > 0
            ) {
                this.$axios
                    .post(url, data, {
                        headers: {
                            Authorization: `Bearer ${token}` // send the access token through the 'Authorization' header
                        }
                    })
                    .then(res => {
                        this.$emit("onSave")
                    })
            } else alert("Empty input.")
            setTimeout(() => {
                this.isSubmitted = false
            }, 1200)
        }
    }
}
</script>

<style scoped></style>
